import React from 'react'
import { motion, useScroll } from "framer-motion"
import ParticlesMain from './ParticlesMain'
import { Link } from 'react-router-dom'



const HomeSection4 = () => {

  const DATA = [
    {
      icon: 'image/home-projects/1.svg',
      title: 'Yurt İçi',
      link: '/referanslar/yurtici',
    },

    {
      icon: 'image/home-projects/2.svg',
      title: 'Yurt Dışı',
      link: '/referanslar/yurtdisi'
    }
  ]



  const list = {
    visible: {


      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },


  }

  const itemm = {
    visible: { opacity: [0, 1, 0.95, 1], scale: [1, 0.95, 1], translateX: 0, translateY: 0, rotate: ['0deg', '0deg', '90deg', '90deg'] },
    hidden: { opacity: 0, translateY: -100 },

  }


  const item1 = {
    visible: { opacity: [0, 1, 0.95, 1] },
    hidden: { opacity: 0 },

  }








  const text = "PROJELER".split(" ");
  const text2 = "High-tech equipment and elaborate control procedures".split(" ");

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }



  return (
    <motion.section initial="hidden" whileInView="visible" variants={list} className="relative   bg-white">
      <div className="  pt-14 px-10 lg:px-48 relative ">
        <motion.h3 variants={itemText} className="text-heading-h3 text-black font-bold leading-10">
          REFERANSLAR
        </motion.h3>
        <motion.p variants={itemText} className="  font-medium mb-14 text-black">
        Bizi tercih edenlere çözüm üretmekten gurur duyuyoruz.

        </motion.p>
      </div>




      <div className="w-full relative  bg-white flex justify-center  pb-14 ">
        <motion.div initial="hidden" whileInView="visible" variants={list} className="w-full lg:w-3/4  grid grid-cols-2 lg:gap-10 gap-1 px-0 lg:px-48 ">

          {
            DATA.map((item, index) => {
              return (
                <Link key={index} to={item.link}>
                <motion.div whileHover={{ scale: 1.1 }} custom={index}  className="p-1 md:py-3 h-full lg:h-60 flex flex-col justify-center items-center shadow-md space-y-7">
                  <motion.img variants={itemm} src={item.icon} className=" h-20 w-20" />
                  <motion.h1 variants={item1} className=" text-2xl  text-black font-bold text-center">{item.title}</motion.h1>
                  <div className='h-10 lg:h-14  b-red-700 flex justify-center items-center relative cursor-pointer'>
                    <motion.div variants={{ visible: { width: '0%', opacity: 1, transition: { duration: 1, ease: 'easeIn' } }, hidden: { width: '100%', opacity: 0 } }} className='absolute h-full w-full bg-tedakblue top-0 left-0'></motion.div>
                    <motion.div variants={{ visible: { opacity: 1, transition: { ease: 'easeIn', duration: 1, delay: 2 } }, hidden: { opacity: 0 } }} className='px-2 lg:px-8 h-full w-full bg-[#EFEFEF] flex justify-center items-center border'>
                      <h6 className='text-base'>Daha Dazlası</h6>
                    </motion.div>
                  </div>
                </motion.div>
                </Link>
              )
            })
          }



        </motion.div>

      </div>




    </motion.section>
  )
}

export default HomeSection4