import React from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'

const Kurumsal = () => {
    return (
        <div className='bg-white'>
            <Banner />


            <div className='inner-container'>
                <div className='w-full  bg-white space-y-16'>
                    <motion.div whileInView={{ opacity: [0, 1], transition: { duration: 1 } }} initial={{ opacity: 0 }}>
                        <h2 className=' general-heading'>Kurumsal</h2>
                        <h2 className='font-medium text-2xl  text-gray-900'>Tecrübe ve Güvenle

                        </h2>
                        <h2 className='font-medium text-xl mb-4 '>
                            Teknolojik Elektrik Dağıtım Kabin
                            Çözümleri.
                        </h2>
                        <p className='text-base text-p text-gray-500'>
                            Tedak Teknolojik Elektrik Dağıtım Kabinleri olarak elektrik dağıtım, izleme ve kontrol kabinlerinin projelendirilmesi, montajı ve devreye alınması alanlarında uzmanlaşmış bir firmadır. 4000m² kapalı alan ve 1100m² açık alana sahip modern fabrikamızda, 17 kişilik beyaz yakalı, 29 kişilik mavi yakalı ekibimizle Siemens Sivacon Teknoloji partnerliği çerçevesinde Sivacon S8, Sivacon S4 ve Alpha Tip testli panoların sistem entegratörlüğünü yapmaktayız.
                            <div className='h-3' />
                            Kurulduğumuz günden bu yana, sektördeki yenilikleri ve teknolojik gelişmeleri yakından takip ederek müşterilerimize en iyi çözümleri sunmaktayız. Profesyonel ekibimiz, projelendirme aşamasından montaj ve devreye almaya kadar tüm süreçlerde en yüksek kalite standartlarına göre hizmet vermektedir.
                            <div className='h-3' />
                            Tedak Teknolojik Elektrik Dağıtım Kabinleri olarak, kaliteli ürün ve hizmetlerimizle sektördeki yerimizi sağlamlaştırıyoruz. Yenilikçi ve sürdürülebilir çözümlerimizle, Türkiye ve global pazarda adımızı duyurmaya devam ediyoruz.

                        </p>
                    </motion.div>

                    <motion.div whileInView={{ opacity: [0, 1], transition: { duration: 1 } }} initial={{ opacity: 0 }}>
                        <h2 className=' general-heading '>Vizyonumuz</h2>
                        <p className='text-p text-gray-500'>Uluslararası arenada Dünya markası olmak hedefimizdir.
                        </p>
                    </motion.div>

                    <motion.div id='Misyonumuz' whileInView={{ opacity: [0, 1], transition: { duration: 1 } }} initial={{ opacity: 0 }}>
                        <h2 className=' general-heading'>Misyonumuz</h2>
                        <p className='text-p text-gray-500'>Firmamız deneyimli uzman kadrosuna Ar-Ge ruhunu
                            benimsetmiş, çağın koşullarına bağlı olarak oluşturduğu
                            çözümler ile müşterilerine kesintisiz ve kaliteli hizmet
                            sunmayı ilke edinmiştir.
                        </p>
                    </motion.div>

                    {/* <motion.div className='h-32' whileInView={{ opacity: [0, 1], transition: { duration: 1 } }} initial={{ opacity: 0 }}>
                        <h2 className='general-heading'>Değerlerimiz</h2>
                        <p className='text-p'></p>
                    </motion.div> */}

                </div>
            </div>
        </div>
    )
}

export default Kurumsal