import React, { useState } from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'
import Lightbox from 'yet-another-react-lightbox'
import "yet-another-react-lightbox/styles.css";
import { Zoom } from 'yet-another-react-lightbox/plugins';

const DATA = [
  {
    title: 'CE',
    image: '/image/certificates/eac.svg',
    url: '/pdf/certificates/TEDAK_CE.PDF'
  },
  {
    title: 'EAC',
    image: '/image/certificates/eac.svg',
    url: '/pdf/certificates/ЕАС.pdf'
  },
  {
    title: 'ISO 9001',
    image: '/image/certificates/9001.svg',
    url: '/pdf/certificates/sertifika-tedak-elektrik-9001-JAS-2023.pdf'
  },
  {
    title: 'ISO 14001',
    image: '/image/certificates/14001.svg',
    url: '/pdf/certificates/sertifika-tedak-elektrik-14001-JAS-2023.pdf'
  },

  {
    title: 'OHSAS 45001',
    image: '/image/certificates/4.jpg',
    url: '/pdf/certificates/sertifika-tedak-elektrik-45001-JAS-2023.pdf'
  },


  {
    title: 'SIVACON Technology Partner',
    image: '/image/certificates/4.jpg',
    url: '/pdf/certificates/Zertifikat_LV0943-001.pdf'
  },

]

const DATA_certificates = [
  {
    id: 0,
    image: '/image/sertifikalar/1.jpg',
  },
  {
    id: 1,
    image: '/image/sertifikalar/2.jpg',
  },
  {
    id: 2,
    image: '/image/sertifikalar/3.jpg',
  },
  {
    id: 3,
    image: '/image/sertifikalar/4.jpg',
  },
  {
    id: 4,
    image: '/image/sertifikalar/5.jpg',
  },
  {
    id: 5,
    image: '/image/sertifikalar/6.jpg',
  },


]

const Sertifikalarimiz = () => {

  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  const itemScale = { visible: { opacity: 1, scale: 1, transition: { duration: 1 } }, hidden: { opacity: 0, scale: 1.2 }, }

  const [toggle, setToggle] = useState('')


  const [open, setOpen] = React.useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const images = [
    { id: 0, src: '/image/sertifikalar/1.jpg', title: 'Image 1' },
    { id: 1, src: '/image/sertifikalar/2.jpg', title: 'Image 2' },
    { id: 2, src: '/image/sertifikalar/3.jpg', title: 'Image 3' },
    { id: 3, src: '/image/sertifikalar/4.jpg', title: 'Image 4' },
    { id: 4, src: '/image/sertifikalar/5.jpg', title: 'Image 5' },
    { id: 5, src: '/image/sertifikalar/6.jpg', title: 'Image 6' },
    // Diğer resimler...
  ];

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };
  return (
    <div className='  '>
      <Banner />
      <motion.div initial="hidden" whileInView="visible" variants={list} className='  w-full bg-white  z-10 inner-container '>
        <h5 className='general-heading '> Sertifikalarımız</h5>

        <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 my-14 '>
          {
            images.map((item, index) => {
              return (
                <img   onClick={() => handleImageClick(item.id)}  key={item.id} src={item.src} className='hover:scale-105 transition-all duration-500 cursor-pointer' />
              )
            })
          }
        </div>





        {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images.map(image => ({ src: image.src, alt: image.title }))}
          index={currentImageIndex}
          plugins={[Zoom]}
        />
      )}



        {/* {
         toggle && 
          <div className='h-screen w-full  fixed top-0 left-0 z-[200] flex justify-center backdrop-blur-sm '>

              <img src={toggle}/>
          </div>

        } */}

        {/* <div className='grid   my-14  gap-5 lg:gap-5'>
          {
            DATA.map((item, index) => {
              return (
                <a target='_blank' href={item.url} className=''>
                  <div className=' w-full flex     group hover:scale-105 transition-all duration-500 space-x-5'>
                    <div variants={itemText} className='    overflow-hidden min-w-20'>
                      <div className='group-hover:scale-105 h-full w-full transition-transform duration-500'>
                        <motion.img variants={itemScale} src={item.image} className=' w-20' />
                      </div>
                    </div>

                    <motion.div variants={itemText} className='flex flex-col justify-center '>
                      <h5 className='font-medium  text-lg border-l-4 border-tedakblue pl-4 group-hover:text-tedakblue transition-all duration-500'>{item.title}</h5>

                    </motion.div>


                  </div>
                </a>
              )
            })
          }



        </div> */}




      </motion.div>
    </div>
  )
}

export default Sertifikalarimiz