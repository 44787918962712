import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'
import { HashRouter, useLocation } from 'react-router-dom';
const Medya = () => {


  let location = useLocation();
  const musteriYorumlari = useRef()

useLayoutEffect(() => {
    console.log(location)
    if(location.hash === '#musteri-yorumlari'){
        window.scrollTo(0,musteriYorumlari.current.offsetTop-70)
    }
    else(    window.scrollTo(0, 0))
}, [location.hash])
  return (
    <div className='  '>

      <Banner />
      <div className=' w-full bg-white  z-10   inner-container'>
        <h5 className='general-heading '> Medya</h5>

        <div className='grid lg:grid-cols-2 my-14 gap-24 '>
          <div className='h-64  space-y-5'>
            <h5 className='text-lg border-l-4 border-tedakblue pl-4'>Fabrika Kabul Testi</h5>
            <iframe width={'100%'} height={'100%'} src="https://www.youtube.com/embed/6a_cO9APgdA?si=Ez3yvNC5NpNdJLvy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className='h-64 space-y-5'>
            <h5 className='text-lg border-l-4 border-tedakblue pl-4'>System Pro E Power</h5>
            <iframe width={'100%'} height={'100%'} src="https://www.youtube.com/embed/Xks7DSagh0k?si=lHnAN91K2cX72g2o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className='h-64  space-y-5'>
            <h5 className='text-lg border-l-4 border-tedakblue pl-4'>TEDAK EPLAN</h5>
            <iframe width={'100%'} height={'100%'} src="https://www.youtube.com/embed/uUinHop6Rkw?si=Cg415njI14aGtvY7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className='h-64  space-y-5'>
            <h5 className='text-lg border-l-4 border-tedakblue pl-4'>Tedak Electric</h5>
            <iframe width={'100%'} height={'100%'} src="https://www.youtube.com/embed/-V5rnwWIu2Y?si=0erKzAua_nIdAVr0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>


      </div>


      <div ref={musteriYorumlari}  id='musteri-yorumlari' className=' w-full bg-white  z-10   inner-container'>
        <h5 className='general-heading '> Müşteri Yorumları</h5>

        <div className='h-96 '></div>
        </div>
    </div>
  )
}

export default Medya