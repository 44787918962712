import React from 'react'
import { motion, useScroll } from "framer-motion"
const Footer = () => {


  const list = {
    visible: {
      opacity: 1,

      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
    hidden: { opacity: 0 },

  }

  const itemText = { visible: { opacity: 1, transition: { duration: 1 } }, hidden: { opacity: 0 }, }
  const itemLine = { visible: { width: '100%', transition: { duration: 1 } }, hidden: { width: '0%' } }


  return (
    <motion.footer initial="hidden" whileInView="visible" variants={list} className="   relative  ">

      <div className=" w-full h-full mx-auto  absolute top-0 ">
        <img src="/image/footer/banner.png" className="h-full w-full object-cover" />
      </div>

      <div className="container relative mx-auto  z-10 h-full  lg:px-28 py-14 px-10 ">
        <div className="flex flex-col">
          <div className="">
            <div className="flex   flex-col lg:flex-row space-y-10 lg:space-y-0">
              <div className="w-full lg:w-2/3 h-10  ">
                <motion.img variants={itemText} src="/image/footer/logo.svg" />

              </div>
              <motion.div variants={itemText} className="w-full lg:w-1/3   flex justify-center lg:justify-end space-x-3">
                <img src="/image/footer/iso.svg" className="w-16" />
                <img src="/image/footer/iso.svg" className="w-16" />
                <img src="/image/footer/iso.svg" className="w-16" />
              </motion.div>
            </div>
            <div className="flex w-full flex-col lg:flex-row lg:space-x-7 py-7 space-y-5 lg:space-y-0">
              <motion.div variants={itemText} className="flex flex-col leading-[1.1] text-white">
                <a className="text-[13px]">Fabrika:</a>
                <a className="text-[13px]">Hacıeyüplü Mahallesi, 3126 Sokak No:3, 20050 Merkezefendi/Denizli</a>
                <a className="text-[13px]">Tel: (0258) 267 23 93</a>
              </motion.div>
              <motion.div variants={itemText} className="flex flex-col leading-[1.1] text-white">
                <a className=" text-[13px]">Ofis:</a>
                <a className=" text-[13px]">360 Office Plaza Ankara Cad. No: 405  Kat: 2, Daire: 18 Pendik /İstanbul</a>
                <a className=" text-[13px]">Tel: (0212) 220 77 36</a>

              </motion.div>
            </div>

          </div>

          <motion.div variants={itemLine} className='h-px w-full bg-white'></motion.div>




          <div className="flex flex-col lg:flex-row w-full mt-7 space-y-4 lg:space-y-0">

            <div className="lg:w-2/3 w-full h-10   flex items-center text-white">
              <motion.p variants={itemText}>Copyright © 2024 TEDAK, tüm hakları saklıdır.<a href='https://macart.com.tr/'>© MAC ART </a></motion.p>
            </div>

            <div className="lg:w-1/3 w-full flex-col lg:flex-row  flex lg:justify-end items-center  lg:space-x-14 space-y-4 lg:space-y-0">
              <motion.a variants={itemText} className="text-white">tedak@tedak.com.tr</motion.a>
              <div className="flex space-x-2">
              <a target='_blank' href='https://www.youtube.com/@tedakpano'><motion.img variants={itemText} src="/image/footer/social/y.png" className="w-8" /></a>
                <a target='_blank' href='https://www.linkedin.com/company/tedakelektrik/?originalSubdomain=tr'><motion.img variants={itemText} src="/image/footer/social/i.svg" className="w-8" /></a>
                <a target='_blank' href='https://www.instagram.com/tedakelektrik/'><motion.img variants={itemText} src="/image/footer/social/in.svg" className="w-8" /></a>
                {/* <motion.img variants={itemText} src="image/footer/social/f.svg" className="w-8" /> */}
              </div>
            </div>

          </div>
        </div>


      </div>

    </motion.footer>
  )
}

export default Footer