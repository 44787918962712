import React, { useState } from 'react'
import { motion } from "framer-motion"
import Banner from '../components/Banner'
const Iletisim = () => {
    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)
    return (
        <div className='bg-white'>
            <Banner/>


            <div className='inner-container'>
                {/* <motion.div whileInView={{ opacity: [0, 1], transition: { duration: 1 } }} initial={{ opacity: 0 }} className='max-w-[430px]'>
                    <h4 className='text-h4 font-medium'>Bize Ulaşın</h4>
                    <p className='text-p'>Bir sorunuz mu var veya bizimle çalışmakla ilgileniyor musunuz? Aşağıdaki formu doldurup bizimle iletişime geçin. Size en kısa sürede cevap vermeye çalışacağız.</p>
                </motion.div> */}

                <div className='grid grid-cols-1 lg:grid-cols-2'>
                    <div className='w-full  bg-white space-y-16 '>



                        <div className='flex flex-col '>
                            <motion.div whileInView={{ opacity: [0, 1], transition: { duration: 1 } }} initial={{ opacity: 0 }}>
                                <h4 className='general-heading'>Şirket Bilgisi</h4>
                            </motion.div>
                            {/* <div className='flex flex-col space-y-6 max-w-96 !mb-12'>
                                <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='Şirket' />
                                <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='Birinci operasyon alanı' />
                                <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='Şirket Büyüklüğü' />


                                <motion.div whileHover={{ scale: 1.1 }} variants={{ visible: { opacity: 1, transition: { ease: 'easeIn', duration: 1, delay: 2 } }, hidden: { opacity: 0 } }} className=' bg-tedakblue flex justify-center items-center border px-8 py-3 w-28 shadow cursor-pointer'>
                                    <h6 className='text-p text-white'>Gönder</h6>
                                </motion.div>

                            </div> */}

                            <div className='space-y-6  my-14'>
                            <div className='hover:bg-slate-50 transition-all duration-500'>
                                <a className='' target='_blank' href='https://maps.app.goo.gl/fKWcbzCrFocZ5dS56'>
                                   
                                    <h5 className='font-semibold'>Tedak Elektrik Elekt. Otomasyon Taah. Makine İth. İhr. SAN. ve TİC. LTD. ŞTİ</h5>
                                   
                                    </a>
                                </div>
                                <div className='hover:bg-slate-50 transition-all duration-500'>
                                <a className='' target='_blank' href='https://maps.app.goo.gl/fKWcbzCrFocZ5dS56'>
                                    <h5 className='font-medium text-tedakblue'>Fabrika:</h5>
                                    <h5 className=''>Hacıeyüplü Mahallesi, 3126 Sokak No:3, 20050 Merkezefendi/Denizli</h5>
                                    <h5 className=''>Tel: (0258) 267 23 93</h5>
                                    </a>
                                </div>
                                <div className='hover:bg-slate-50 transition-all duration-500'>
                                <a className='' >
                                    <h5 className='font-medium text-tedakblue'>Ofis:</h5>
                                    <h5 className=''>360 Office Plaza Ankara Cad. No: 405 Kat: 2, Daire: 18 Pendik /İstanbul</h5>
                                    <h5 className=''>Tel: (0212) 220 77 36</h5>
                                    </a>
                                </div>
                               
                                <div className='hover:bg-slate-50 transition-all duration-500'>
                                <a className='' href='mailto:tedak@tedak.com.tr'>
                                    <h5 className='font-medium text-tedakblue'>Mail:</h5>
                                   <h5 className=''>tedak@tedak.com.tr</h5>
                                   </a>
                                </div>


                            </div>
                        </div>

                    </div>

                    <div className='w-full  bg-white space-y-6 lg:pt-32 pt-0'>
                        <div className='h-72 '>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.1868010725116!2d29.03826679999999!3d37.8325125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c76ab1b74dfc03%3A0x168ce7f73c710a88!2sTedak!5e0!3m2!1sen!2str!4v1715778546566!5m2!1sen!2str" width="100%" height="100%"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                    {/* <div className='w-full  bg-white space-y-6 pt-16'>

                        <motion.div whileInView={{ opacity: [0, 1], transition: { duration: 1 } }} initial={{ opacity: 0 }}>
                            <h4 className='text-h4 font-medium'>İletişim Detayları</h4>

                        </motion.div>

                        <div className='flex flex-col space-y-6 max-w-96'>
                            <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='İsim' />
                            <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='Soyadı' />
                            <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='Şirketteki göreviniz' />
                            <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='Telefon' />
                            <input className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='E-posta * *' />
                            <textarea rows={5} className='bg-[#E5E3E3] text-tedakblue placeholder-tedakblue text-p px-8 py-3' placeholder='İletmek istediğiniz mesajlarını giriniz.' />



                            <div className=''>
                                <div className='flex space-x-3'>
                                    <div className='max-h-[15px]  min-w-[15px] relative bg-white flex justify-center items-center  top-1.5 border-2 '>
                                        <div className={`h-[9px] w-[9px] bg-tedakblue ${check1 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}></div>
                                        <input type='checkbox' onClick={() => setCheck1(!check1)} className='h-full w-full absolute z-0 opacity-0 ' />

                                    </div>
                                    <h5 className=' leading-0 underline text-black'>Kişisel Verilerin Korunması Kanunu Bilgilendirmesini okudum.</h5>
                                </div>

                                <div className='flex space-x-3'>
                                    <div className='max-h-[15px]  min-w-[15px] relative bg-white flex justify-center items-center  top-1.5 border-2 '>
                                        <div className={`h-[9px] w-[9px] bg-tedakblue ${check2 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}></div>
                                        <input type='checkbox' onClick={() => setCheck2(!check2)} className='h-full w-full absolute z-0 opacity-0 ' />

                                    </div>
                                    <h5 className=' leading-0  text-black'>Kişisel verilerimin ve özel nitelikli kişisel verilerimin işlenmesine, saklanmasına ve 3. kişilere aktarılmasına muvafakat ederim. Garanti Filo ve iştiraklerinin kampanya, duyuru, ürün ve hizmetlerinden SMS, telefon veya e-posta yoluyla haberdar olmak istiyorum. (Kabul etmiyorsanız kutudaki işareti kaldırınız)</h5>
                                </div>
                            </div>


                            <motion.div whileHover={{ scale: 1.1 }} variants={{ visible: { opacity: 1, transition: { ease: 'easeIn', duration: 1, delay: 2 } }, hidden: { opacity: 0 } }} className=' bg-tedakblue flex justify-center items-center border px-8 py-3 w-28 shadow cursor-pointer'>
                                <h6 className='text-p text-white'>Gönder</h6>
                            </motion.div>


                        </div>

                    </div> */}


                </div>
            </div>
        </div>
    )
}

export default Iletisim